import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="More Menu Items" />
    <h1>Itinerary</h1>
    <h2>Sun December 25</h2>
    <p>Breakfast casserole / chicken thighs, salad / dumplings/wontons</p>
    <h2>Mon December 26</h2>
    <p>Breakfast casserole / chicken wings, salad / jook story </p>
    <h2>Tue December 27 - Da Da and Puo Puo leave</h2>
    <p>Breakfast casserole / hot pot / tbd</p>
    <h2>Fri December 30 - Ayis arrive</h2>
    <p>tbd / tbd / tbd</p>
    <h2>Sat December 31</h2>
    <p>tbd / homemade pizza / tbd</p>
    <h2>Sun January 1</h2>
    <p>tbd / brisket / tbd</p>
    <h2>Mon January 2</h2>
    <p>tbd / pork butt / tbd</p>
    <h2>Tue January 3 - Da ayi leaves</h2>
    <p>tbd / turkey / tbd</p>
    <h2>Wed January 4 - Xiao ayi leaves</h2>
    <p>tbd / tbd / tbd</p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)




export default SecondPage
